@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff;
}

img {
  border-radius: 5px;
}

.bg-card {
  background-color: #ffffff;
}
.bg-button {
  border: none;
  background-color: #173e43;
}
.bg-button-create {
  background-color: #0b6411;
  text-align: center;
}
.bg-button-create:hover {
  background-color: #134715;
  text-align: center;
}
.bg-button-create:active:focus {
  background-color: #134715;
  text-align: center;
}
.bg-button-create:active {
  background-color: #134715;
  text-align: center;
}

.mat-success {
  background-color: #0b6411;
  text-align: center;
}

.mat-delete {
  background-color: #a0241b;
  text-align: center;
}

.mat-edit {
  background-color: #5a5757;
  text-align: center;
}

.mat-details {
  background-color: #262144;
  text-align: center;
}

.bg-button-pdf {
  background-color: #4e0c0c;
  text-align: center;
}

.bg-button-pdf:hover {
  background-color: #3b1111;
  text-align: center;
}

.bg-button-pdf:active:focus {
  background-color: #3b1111;
  text-align: center;
}

.bg-button-update {
  background-color: #0b6411;
  text-align: center;
}

.bg-button-update:hover {
  background-color: #2a6a2e;
  text-align: center;
}

.bg-button-update:active:focus{
  background-color: #2a6a2e;
  text-align: center;
}

.bg-button-work {
  background-color: #254e19;
  text-align: center;
}
.bg-button-work:hover {
  background-color: #3a662c;
  text-align: center;
}
.bg-button-work:active:focus {
  background-color: #3a662c;
  text-align: center;
}

.bg-button-stock {
  background-color: #1f5310;
  text-align: center;
}

.bg-button-stock:hover {
  background-color: #1f5310;
  text-align: center;
}

.bg-button-stock:active:focus {
  background-color: #1f5310;
  text-align: center;
}

.bg-button-cancel {
  background-color: #a0241b;
  text-align: center;
}


.bg-button-delete {
  background-color: #a0241b;
  text-align: center;
}

.bg-button-delete:hover {
  background-color: #691a15;
  text-align: center;
}

.mat-delete {
  background-color: #a0241b;
  text-align: center;
}

.mat-info {
  background-color: #036677;
  text-align: center;
}
.mat-delivery {
  background-color: #930320;
  text-align: center;
}

.btn-block {
  display: block;
  width: 100%;
  margin: 0 10px 20px 0;
}

.mat-payment {
  background-color: #375f38;
  text-align: center;
}
.mat-uncollectible {
  background-color: #e68506;
  text-align: center;
}
.bg-button-delivery {
  background-color: #930320;
  text-align: center;
}

.bg-button-delivery:hover {
  background-color: #d6183e;
}

.bg-button-details {
  background-color: #262144;
  text-align: center;
}

.bg-button-details:hover {
  background-color: #685f99;
  text-align: center;
}

.bg-button-payment {
  background-color: #375f38;
  text-align: center;
}

.bg-button-payment:hover {
  background-color: #59915b;
  text-align: center;
}

.bg-button-uncollectible {
  background-color: #e68506;
  text-align: center;
}

.bg-button-uncollectible:hover {
  background-color: #ac7228;
}

.bg-button-info {
  background-color: #036677;
  text-align: center;
}

.bg-button-info:hover {
  background-color: #457077;
  text-align: center;
}

.bg-button-2 {
  background-color: #0e5875;
}

.color-title {
  color: #fa255e;
}

.card {
  border: none;
}

.border-3 {
  border: 5px solid;
  border-width: 5px !important;
  border-color: #173e43;
  border-top: 0cm;
}

.border-4 {
  border: 5px solid;
  border-width: 3px !important;
  border-color: #173e43;
}

.border-2 {
  border: 5px solid;
  border-width: 1px !important;
  border-color: #173e43;
  border-top: 0cm;
  border-style: groove;
}

.border-0 {
  border: 5px solid;
  border-width: 0px !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}
/* for sidenav to take a whole page */
html,
body,
app-root {
  min-height: 100%;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  background: #ffffff;
}

body {
  min-height: 100vh;
  font-family: 'Prompt', sans-serif;
  background-color: FFFFFF;
  margin: 0 auto;
}

.modal-open .modal {
  display: flex !important;
  align-items: center !important;
}

.modal-backdrop {
  z-index: -1;
}
